<template>
	<div>
		<form-remote-select v-model="submitValue"
							v-model:active="isActive"
							:allow-create="false"
							@update:modelValue="value => emit('update:modelValue', value)"
							@loaded="items => emit('loaded', items)"
							:action="`/v1/events/${props.eventId}/participation-classes/options`"
							text-prop="name"
							:label="props.label"
							:help="props.help"
							:rules="props.rules"
							:large="props.large"
							:disabled="props.disabled"
							:placeholder="props.placeholder"
							:value-prop="props.valueProp"
							:subtitle-prop="props.subtitleProp"
							:help-prop="props.subtitleProp"
							v-model:selected="selectedParticipationClass"
							:color="props.color"
							:data="{limit: 10}">

			<template v-if="props.allowCreate" #below_options="{heightStyle}">
				<div class="border-t pt-4">
					<form-select-option :height-style="heightStyle"
										:color="props.color"
										:text="$t('action.create_name', {name: $t('label.participation_class')})"
										:icon="icons.create" @toggle="createParticipationClass"/>
				</div>
			</template>
		</form-remote-select>

		<ajax-form-modal v-if="props.allowCreate" v-model="showCreateModal"
						 :form-options="{action: `/v1/athletes/my/events/${props.eventId}/participation-classes`, data: creationData, method: 'POST'}"
						 :modal-options="{title: $t('action.create_name', {name: $t('label.participation_class')}), subtitle: $t('help.event.participation_class')}"
						 :submit-text="$t('action.create')"
						 @success="handleCreatedParticipationClass">

			<participation-class-form-inputs :data="creationData" :color="props.color"/>

		</ajax-form-modal>
	</div>
</template>

<script setup lang="ts">
import {createIcon} from "@spoferan/nuxt-spoferan/icons";

const props = withDefaults(defineProps<{
	modelValue?: number,

	eventId: number,

	label?: string,
	help?: string,
	placeholder?: string,
	rules?: object,
	disabled?: boolean,
	large?: boolean,

	// Whether custom values are allowed
	allowCreate?: boolean,

	// The selected option object to keep in sync
	selected?: object,

	valueProp?: string,
	subtitleProp?: string,

	color?: string,

	defaultCreateData?: object

}>(), {
	valueProp: 'id',
	subtitleProp: 'goal_info',
	defaultCreateData: {},
});
const emit = defineEmits(['update:modelValue', 'update:selected', 'created', 'loaded']);

const submitValue = ref(props.modelValue);
watch(submitValue, (newValue) => {
	emit('update:modelValue', newValue);
});
watch(() => props.modelValue, (newValue) => {
	submitValue.value = newValue;
});

const selectedParticipationClass = ref(props.selected);
watch(selectedParticipationClass, (newValue) => {
	emit('update:selected', newValue)
});
watch(() => props.selected, (newValue) => {
	selectedParticipationClass.value = newValue;
});

const isActive = ref(false);
const showCreateModal = ref(false);

const creationData = ref({...props.defaultCreateData});
watch(() => props.defaultCreateData, (newValue) => {
	creationData.value = {...newValue};
});

const icons = {
	create: createIcon,
};

function createParticipationClass() {
	if (props.allowCreate) {
		isActive.value = false;
		showCreateModal.value = true;
	}
}

function handleCreatedParticipationClass(participationClass) {
	submitValue.value = participationClass.id;
	emit('created', participationClass)
}
</script>