<template>
	<div>
		<form-input v-model="props.data.name" name="name"
					:rules="{required: true, min: 5, max: 150}"
					:placeholder="$t('placeholder.event.name')"
					:label="$t('label.name')"/>

		<form-date-input v-model="props.data.date"
						 name="date"
						 :rules="{required: true}"
						 :disabled="props.disabled"
						 :label="$t('label.date')"/>

		<form-country-select v-model="props.data.country_code"
							 :disabled="disabled"
							 :color="props.color"
							 name="country_code"
							 :placeholder="$t('placeholder.country_code')"
							 :label="$t('label.event_location')"/>

		<participation-class-form-inputs :data="props.data.participation_classes[0]"
										 :disabled="props.disabled" without-name/>

	</div>
</template>

<script setup lang="ts">
import {removeIcon, createIcon} from "@spoferan/nuxt-spoferan/icons";
import {mdiCalendar} from '@mdi/js'

const props = defineProps<{
	// The data on which to store the input data
	data: { [key: string]: any },

	// Whether all inputs shall be disabled
	disabled?: boolean,

	// Whether the inputs are used to edit the resource
	edit?: boolean,

	color?: string
}>();

const icons = {
	create: createIcon,
	remove: removeIcon,
	locate: mdiCalendar,
};

watch(() => props.data.name, (newValue) => {
	props.data.participation_classes[0].name = newValue;
});

</script>