<template>
	<div>
		<form-input v-if="!props.withoutName" v-model="props.data.name" name="name"
					:rules="{required: true, min: 3, max: 100}"
					:placeholder="t('placeholder.participation_class.name')"
					:label="t('label.name')"/>

		<form-select v-model="props.data.team_mode"
					 :rules="{required: true}"
					 name="team_mode"
					 :options="teamModeOptions"
					 :disabled="props.disabled"
					 :color="props.colorStyle"
					 :label="t('label.team_mode')"/>

		<form-sport-type-select name="sport_type_id" v-model="props.data.sport_type_id"
								:rules="{required: true}"
								value-prop="id"
								:disabled="props.disabled"
								:color="props.color"
								v-model:selected="selectedSportTypeOption"
								:placeholder="t('placeholder.select')"
								:label="t('label.sport_type')"/>

		<form-remote-select v-if="selectedSportTypeOption?.formats_count"
							v-model="props.data.format_id"
							name="format_id"
							:label="`${selectedSportTypeOption.title}: ${t('label.format')}`"
							:action="`/v1/sports/${props.data.sport_type_id}/formats/options`"
							:data="{ts: selectedSportTypeOption.cache_buster}"
							value-prop="id"
							text-prop="title"
							help-prop="description"
							subtitle-prop="description"
							:filterable="false"
							:unselect="$t('label.other')"
							as-guest
							:color="props.colorStyle"
							:disabled="props.disabled" @select="handleSelectedSportTypeFormat"/>

		<template v-if="props.data.sport_type_id">

			<div v-show="shouldShowMetricSelect">
				<form-remote-select v-model="props.data.rank_metric" name="rank_metric"
									:action="'/v1/rank-metrics'"
									:data="rankMetricsLoadData"
									:label="t('label.rank_metric')"
									:help="t('help.participation_class.rank_metric')"
									:placeholder="t('placeholder.select')"
									:disabled="props.disabled"
									:color="props.color"
									value-prop="value"
									help-prop="description"
									:filterable="false"
									as-guest @loaded="setInitialRankMetric"/>

				<form-remote-select v-model="props.data.goal_metric" name="goal_metric"
									:action="'/v1/rank-metrics'"
									:data="goalMetricsLoadData"
									:label="t('label.goal_metric')"
									:help="t('help.participation_class.goal_metric')"
									:disabled="props.disabled"
									:unselect="t('label.without_goal_metric')"
									v-model:selected="selectedGoalMetric"
									:color="props.color"
									value-prop="value"
									help-prop="description"
									:filterable="false"
									clearable as-guest @loaded="setInitialGoalMetric"/>
			</div>

			<dynamic-form-input v-if="selectedGoalMetric && goalMetricInputData" v-model="props.data.goal_value" name="goal_value"
								:label="selectedGoalMetric.value_title"
								:help="selectedGoalMetric.value_help"
								:rules="{required: true}"
								:type="goalMetricInputData.type"
								:input-props="goalMetricInputData.props"
								:placeholder="goalMetricInputData.placeholder"
								:disabled="props.disabled"/>

			<div v-if="!shouldShowMetricSelect" class="flex justify-end">
				<btn text color="primary" @click="shouldShowMetricSelect = true">{{ $t('question.other_goal_metric') }}</btn>
			</div>
		</template>

	</div>
</template>

<script setup lang="ts">

import {EnumUtils, getParticipationClassTeamModeLabel, ParticipationClassTeamMode, RankMetric} from "@spoferan/spoferan-ts-core";

const {t} = useI18n();
const {$apiFetch, $date} = useNuxtApp();

const props = defineProps<{
	// The data on which to store the input data
	data: { [key: string]: any },

	// Whether all inputs shall be disabled
	disabled?: boolean,

	// Whether the inputs are used to edit the resource
	edit?: boolean,

	color?: string,

	withoutName?: boolean,
}>();

const selectedGoalMetric = ref(null);
const selectedSportTypeOption = ref(null);
const shouldShowMetricSelect = ref(false);

watch(() => props.data.sport_type_id, (newValue, oldValue) => {
	if (newValue && oldValue && newValue !== oldValue) {
		props.data.format_id = undefined;
	}
});

const rankMetricsLoadData = computed(() => {
	return {type: 'rank_metric', sport_type_id: props.data.sport_type_id}
});

const goalMetricsLoadData = computed(() => {
	return {type: 'goal_metric', sport_type_id: props.data.sport_type_id}
});

const goalMetricInputData = computed(() => {
	switch (props.data.goal_metric) {
		case RankMetric.Time:
			return {
				type: 'input',
				props: {mask: '##:##:##'},
				placeholder: 'hh:mm:ss'
			};
		case RankMetric.Distance:
		case RankMetric.Altitude:
			return {
				type: 'number',
				props: {
					suffix: t('label.meters')
				}
			};
	}

	return null;
});

const teamModeOptions = EnumUtils.values<ParticipationClassTeamMode>(ParticipationClassTeamMode).map(value => {
	return {
		text: t('event_info.team_mode.' + getParticipationClassTeamModeLabel(value) + '.label', {team_label: t('label.squad')}),
		value: value,
	}
});

function setInitialRankMetric(options) {
	props.data.rank_metric = options.length ? options[0].value : undefined;
	shouldShowMetricSelect.value = false;
}

function setInitialGoalMetric(options) {
	props.data.goal_metric = options.length ? options[0].value : undefined;
	shouldShowMetricSelect.value = false;
}

function handleSelectedSportTypeFormat(format) {
	if (format.total_distance && props.data.goal_metric === RankMetric.Distance) {
		props.data.goal_value = format.total_distance;
	}
}

</script>