<template>
	<div>
		<form-event-select v-model="props.data.event_id"
						   option-url="/v1/views/my-events/create/events"
						   name="event_id"
						   large
						   :autofocus="!props.data.event_id"
						   :rules="{required: true}"
						   :disabled="props.disabled || props.fixedEvent"
						   v-model:selected="selectedEvent"
						   :placeholder="t('placeholder.select')"
						   :label="t('label.event')" allow-create/>

		<template v-if="selectedEvent && !selectedEvent.allows_manual_participations">
			<alert type="warning" :content="t('text.event.add_manual_participation_for_managed_event', {event: selectedEvent.display_name})" :to="`/events/${selectedEvent._key}`"
				   :button="t('action.to_event')" vertical/>
			<validation-input :model-value="false" :error="t('text.event.add_manual_participation_for_managed_event', {event: selectedEvent.display_name})"/>
		</template>
		<template v-else-if="props.data.event_id">
			<form-participation-class-select v-model="props.data.participation_class_id"
											 :event-id="props.data.event_id"
											 name="participation_class_id"
											 :rules="{required: true}"
											 :disabled="props.disabled"
											 v-model:selected="selectedParticipationClass"
											 :default-create-data="defaultParticipationClassCreateData"
											 :placeholder="t('placeholder.select')"
											 :label="t('label.participation_class')"
											 :help="t('help.event.participation_class')"
											 :allow-create="selectedEvent?.is_public_editable" @loaded="handleLoadedParticipationClassOptions"/>

			<expansion-panels v-if="selectedParticipationClass" open>
				<expansion-panel :title="t('label.participation_details')">

					<form-gender-select v-if="!existingGender"
										v-model="props.data.gender" name="gender"
										:disabled="props.disabled"
										clearable
										:help="t('help.participation.gender')"
										:label="t('label.gender')"/>

					<form-native-date-input v-if="!suggestedAgeClass && supportsAgeClasses"
											v-model="props.data.birthday"
											name="birthday"
											:disabled="props.disabled"
											:rules="{max: $date().format('YYYY-MM-DD')}"
											clearable
											:help="t('help.participation.birthday')"
											:label="t('label.birthday')"/>

					<form-club-select v-model="props.data.club_id"
										   :color="props.color"
										   :help="t('help.participation.club_id')"
										   name="club_id"
										   :disabled="props.disabled || props.disabledFields.includes('club_id')"
										   allow-create
										   :clearable="!props.disabledFields.includes('club_id')"
										   :label="t('label.club_company')"/>

					<lazy-form-team-select v-if="selectedParticipationClass.team_mode !== ParticipationClassTeamMode.Disabled" v-model="props.data.team_id"
										   :disabled="props.disabled"
										   :event-id="props.data.event_id"
										   name="team_id"
										   :color="props.color"
										   :participation-class-id="props.data.participation_class_id"
										   allow-create
										   clearable
										   :rules="{required: selectedParticipationClass.team_mode === ParticipationClassTeamMode.Required}"
										   :help="selectedParticipationClass.team_mode === ParticipationClassTeamMode.Required ? t('text.participation.choose_participation_team') : t('text.participation.choose_participation_team_optional')"
										   :label="selectedParticipationClass.team_label ?? t('label.squad')"/>

					<form-input v-model="props.data.starter_number" name="starter_number"
								:rules="{min: 1, max: 8}"
								:disabled="props.disabled"
								:placeholder="t('label.unknown')"
								:label="t('label.starter_number')"/>

					<form-switch name="private" v-model="props.data.private" :label="t('input.participation.private')"
								 :disabled="props.disabled"
								 :help="t('help.participation.private')"/>

				</expansion-panel>
				<expansion-panel v-if="$date(selectedParticipationClass.start_date).isBefore()" :title="t('label.results')">
					<loader v-if="isLoadingRanks" class="h-240"/>
					<template v-else>
						<template v-if="displayExistingRanks.length">

							<alert v-if="displayExistingRanks.length === 1" type="success" :content="$t('text.participation.create_manual_with_existing_results')"/>
							<alert v-else type="warning" :content="$t('text.participation.create_manual_with_non_unique_existing_results')"/>

							<card v-for="(rankData, index) in displayExistingRanks" :key="index" outlined>
								<card-text>
									<description-list>
										<description-list-item :title="$t('label.status')" :text-space="2/5" >
											<template #text>
												<chip :color="rankData.status.color" small>{{ rankData.status.title }}</chip>
											</template>
										</description-list-item>

										<description-list-item v-if="rankData.starter_number !== null" :title="$t('label.starter_number')"
															   :text="rankData.starter_number" :text-space="2/5" />

										<description-list-item v-if="rankData.primary_rank_value !== null" :title="$t(`enum.rank_metric.${rankData.primary_rank_value_prop}.title`)"
															   :text="rankData.primary_rank_value" :text-space="2/5" />

										<description-list-item v-for="(rank, rankIndex) in rankData.ranks" :key="`${index}-${rankIndex}`" :text-space="2/5" center>
											<template #title>
											<span class="flex flex-column">
												<span class="text-sm truncate">{{ rank.list_name }}</span>
												<span class="text-3xs truncate">{{ rank.list_group_name }}</span>
											</span>
											</template>
											<template #text>
												<chip :color="getColorForRank(rank.rank)" :icon="icons.rank" small>
													<span class="font-bold">{{ rank.rank }}. {{ $t('label.rank') }}</span>
												</chip>
											</template>
										</description-list-item>

									</description-list>
								</card-text>
							</card>
						</template>
						<template v-else>
							<manual-participation-results-form-inputs :data="props.data"
																	  :disabled="props.disabled"
																	  :color="props.color"
																	  :gender="props.data.gender"
																	  :with-ranks="supportsRanks"
																	  :age-class="supportsAgeClasses ? suggestedAgeClass ?? (props.data.birthday ? {} : null) : undefined"
																	  :participation-class="selectedParticipationClass"/>
						</template>

					</template>

				</expansion-panel>

			</expansion-panels>
		</template>

	</div>
</template>

<script setup lang="ts">

import {ParticipationClassTeamMode, ParticipationStatus} from "@spoferan/spoferan-ts-core";
import {mdiMedalOutline} from "@mdi/js";

const {t} = useI18n();
const {$apiFetch, $date, $auth} = useNuxtApp();

const props = withDefaults(defineProps<{
	// The data on which to store the input data
	data: { [key: string]: any },

	// The user key to add participations for
	userKey: string|number,

	// Whether all inputs shall be disabled
	disabled?: boolean,

	// Whether the event can not be changed
	fixedEvent?: boolean,

	// Whether the inputs are used to edit the resource
	edit?: boolean,

	color?: string,

	// The input fields that shall be disabled on the form
	disabledFields?: string[]
}>(), {
	disabledFields: []
});

const icons = {
	rank: mdiMedalOutline,
}

const existingRanks = ref([]);
const isLoadingRanks = ref(false);
const displayExistingRanks = computed(() => {
	if (!props.data.starter_number || !props.data.starter_number.length) {
		return existingRanks.value;
	}
	return existingRanks.value.filter(rankData => rankData.starter_number && rankData.starter_number.startsWith(props.data.starter_number));
});

const existingGender = ref(props.userKey === 'my' || props.userKey === $auth.id ? $auth.user?.gender : null);
const supportsAgeClasses = ref(false);
const supportsRanks = ref(false);
const suggestedAgeClass = ref(null);

const selectedEvent = ref(null);
watch(() => props.data.event_id, () => {
	props.data.participation_class_id = null;
	selectedParticipationClass.value = null;
});

const selectedParticipationClass = ref(null);
watch(() => props.data.participation_class_id, (newValue) => {
	if (newValue) {
		refreshCreateData();
	}
});
watch(selectedParticipationClass, (newValue) => {
	if (newValue && $date(newValue.start_date).isBefore()) {
		props.data.status = ParticipationStatus.Ranked;
	}
});

const {getColorForRank} = useColorHelpers();

const defaultParticipationClassCreateData = computed(() => {
	return {
		start_date: selectedEvent.value?.start_date,
		end_date: selectedEvent.value?.end_date,
		sport_type_id: selectedEvent.value?.main_sport_type_id,
		team_mode: ParticipationClassTeamMode.Disabled
	}
});

async function refreshCreateData() {
	isLoadingRanks.value = true;
	try {
		const {data} = await $apiFetch(`/v1/athletes/${props.userKey}/events/${props.data.event_id}/manual-participations/create`, {
			params: {
				participation_class_id: props.data.participation_class_id
			}
		});
		existingRanks.value = data.existingResults;
		suggestedAgeClass.value = data.participation.ageClass;
		supportsAgeClasses.value = data.participation.supports_age_classes;
		supportsRanks.value = data.participation.supports_ranks;
		existingGender.value = data.participation.gender;
		props.data.gender = existingGender.value;
	} catch (e) {
		console.error(e);
		existingRanks.value = [];
		suggestedAgeClass.value = null;
		supportsAgeClasses.value = false;
		existingGender.value = props.userKey === 'my' || props.userKey === $auth.id ? $auth.user?.gender : null;
		props.data.gender = existingGender.value;
	} finally {
		isLoadingRanks.value = false;
	}
}

function handleLoadedParticipationClassOptions(options) {
	if (options.length === 1) {
		props.data.participation_class_id = options[0].id;
	}
}

</script>