<template>
	<div>
		<form-remote-select v-model="submitValue"
							v-model:active="isActive"
							v-model:filter="filterValue"
							:allow-create="false"
							@update:modelValue="value => emit('update:modelValue', value)"
							:action="props.optionUrl"
							text-prop="display_name"
							:label="props.label"
							:rules="props.rules"
							:large="props.large"
							:autofocus="props.autofocus"
							:disabled="props.disabled"
							:placeholder="props.placeholder"
							:value-prop="props.valueProp"
							:subtitle-prop="props.subtitleProp"
							:help-prop="props.subtitleProp"
							image-prop="avatar_thumbnail"
							v-model:selected="selectedEvent"
							:color="props.color"
							:data="{limit: 10}">

			<template v-if="props.allowCreate" #below_options="{heightStyle}">
				<div class="border-t pt-4">
					<form-select-option :height-style="heightStyle"
										:color="props.color"
										:text="$t('action.create_name', {name: $t('label.event')})"
										:icon="icons.create" @toggle="createEvent"/>
				</div>
			</template>
		</form-remote-select>

		<ajax-form-modal v-if="props.allowCreate" v-model="showCreateModal"
						 :form-options="{action: '/v1/athletes/my/events', data: creationData, method: 'POST'}"
						 :modal-options="{title: $t('action.create_name', {name: $t('label.event')})}"
						 :submit-text="$t('action.create')"
						 @success="handleCreatedEvent">

			<event-form-inputs :data="creationData" :color="props.color"/>

		</ajax-form-modal>
	</div>
</template>

<script setup lang="ts">
import {createIcon} from "@spoferan/nuxt-spoferan/icons";
import {ParticipationClassTeamMode} from "@spoferan/spoferan-ts-core";

const props = withDefaults(defineProps<{
	modelValue?: number,
	label?: string,
	placeholder?: string,
	rules?: object,
	disabled?: boolean,
	large?: boolean,
	autofocus?: boolean,

	// The API EP to fetch the options from
	optionUrl?: string,

	// Whether custom values are allowed
	allowCreate?: boolean,

	// The selected option object to keep in sync
	selected?: object,

	valueProp?: string,
	subtitleProp?: string,

	color?: string,

}>(), {
	optionUrl: '/v1/events/options',
	valueProp: 'id',
	subtitleProp: 'date_info'
});
const emit = defineEmits(['update:modelValue', 'update:selected', 'created']);

const filterValue = ref(null);

const submitValue = ref(props.modelValue);
watch(submitValue, (newValue) => {
	emit('update:modelValue', newValue);
});
watch(() => props.modelValue, (newValue) => {
	submitValue.value = newValue;
});

const selectedEvent = ref(props.selected);
watch(selectedEvent, (newValue) => {
	emit('update:selected', newValue)
});
watch(() => props.selected, (newValue) => {
	selectedEvent.value = newValue;
});

const isActive = ref(false);
const showCreateModal = ref(false);

// The data to create a new event
const creationData = ref({});

const icons = {
	create: createIcon,
};

function createEvent() {
	if (props.allowCreate) {
		creationData.value = {
			name: filterValue.value,
			country_code: 'de',
			participation_classes: [{
				name: filterValue.value,
				team_mode: ParticipationClassTeamMode.Disabled
			}],
		}

		isActive.value = false;
		showCreateModal.value = true;
	}
}

function handleCreatedEvent(event) {
	submitValue.value = event.id;
	emit('created', event)
}
</script>